.modal-content-order {
  position: relative;
  border-radius: 0;
  width: 100%;
  height: 100vh;
  margin: 0 !important;
  background-color: #fff;
  overflow: scroll;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 32px;

    button {
      margin: 0;
      padding: 0;
    }

    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var( --Utility-Colors-Primary-Text);
      margin: 0;
    }

    span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var(--Utility-Colors-Secondary-Text);
    }

    img {
      filter: var(--Custom-Colors-Svg);
    }

    .close {
      opacity: 1;
    }
    .hidden {
      opacity: 0;
    }
  }

  .modal-header:after,
  .modal-header::before {
    content: none;
  }

  .modal-body {
    padding: 15px 30px 76px;

    .min-order {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 24px;
      color: #195154;
      position: absolute;
      z-index: 3;
      left: 45px;
    }

    .filter-option {
      margin-top: 8px;
    }

    .form__select-sort {
      width: 100%;
      .MuiFormLabel-root {
        color: var(--Utility-Colors-Primary-Text);
        font-family: "Montserrat";
      }
      .dropdown {
        width: 100%;
        margin-bottom: 16px;
        background: var(--Utility-Colors-Secondary-Background);
        border-radius: 40px;
        color: var(--Utility-Colors-Secondary-Text);
        font-family: "Montserrat";

        button:active,
        button:hover,
        button:focus {
          outline: none !important;
        }

        .MuiOutlinedInput-notchedOutline {
          border: 0;
        }

        .dropdown-menu {
          span {
            font-weight: normal;
            color: #3e4142;
          }
        }

        .dropdown-toggle::after {
          margin: 0 0 -0.4em -0.4em;
        }

        .MuiSvgIcon-root {
          filter: var(--Custom-Colors-Svg);
        }

        .MuiButtonBase-root {
          color: var(--Utility-Colors-Primary-Text);
          font-family: "Montserrat";
        }
      }
    }

    .option-title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
    }

    .dropdown-divider {
      margin-bottom: 16px;
    }

    .drop-options {
      position: relative;
      flex-flow: column;
      justify-content: flex-start;

      .option {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #3e4142;
        margin-bottom: 24px;
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 18px;

        .round {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #e0e0e0;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        .round:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: -3px;
          bottom: -1px;
          background: #4f4f4f;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        input:checked + .round {
          background: #3cb2b9;
        }

        input:checked + .round:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
          background: #195154;
        }

        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
    }

    .values-container {
      display: flex;
      margin-top: 50px;
      flex-flow: column;

      b {
        font-size: 12px;
        line-height: 16px;
        color: #333333;
      }

      .slider-horizontal {
        width: 100%;

        .slider-handle {
          border: 4px solid var(--Brand-Colors-Primary-Color);
          background: #fff;
        }

        .slider-track {
          height: 5px;
          top: 12px;

          .slider-selection {
            background-image: none;
            background-color: var(--Brand-Colors-Primary-Color);
          }
        }

        .tooltip-inner {
          background-color: var(--Brand-Colors-Primary-Color);
        }

        .arrow,
        .arrow::before {
          border-top-color: var(--Brand-Colors-Primary-Color);
          border-bottom-color: var(--Brand-Colors-Primary-Color);
        }
      }
    }
  }

  .modal-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .btn-orange {
      width: 309px;
      height: 52px;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      margin: 0 auto;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      color: var(--Brand-Colors-White-color-fix);
    }
  }
}
