.a-cars-container {
  display: flex;
  flex-flow: column;
  align-items: center;

  .swiper {
    padding: 30px 0;
    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: #7b7b7b;
        border: 0px;
      }
    }
  }

  .join-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    .title-container {
      text-align: center;

      h1 {
        font-family: "Montserrat";
        color: #195154;
        margin: 32px 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }

  .reservation-card {
    .empty_cars {
      width: 309.88px;
      height: 237px;
      margin: 0px auto;
      display: block;
    }

    .empty-text {
      text-align: center;
      font-size: 18px;
      width: 310px;
      line-height: 30px;
      color: #3cb2b9;
    }

    .empty-text,
    .empty-btn-orange {
      font-family: "Montserrat";
      font-weight: bold;
    }

    .empty-btn-orange {
      width: 311px;
      height: 52px;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    .car-card {
      .list-group {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .list-group-item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          border: 0px;
          flex-direction: column;
          box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
          border-radius: 8px;
          width: 327px;
          height: 485px;
          padding: 0;
          margin: 12px 0;

          p {
            margin: 0;
          }

          .reservation-text {
            h2 {
              font-weight: bold;
              margin-top: 11px;
              text-overflow: ellipsis;
              overflow: hidden;
              font-weight: 800;
              font-size: 18px;
              line-height: 22px;
              margin: 0;
              padding: 6px;
            }

            p {
              font-weight: normal;
              color: #333333;
              font-weight: 600;
              padding: 0 6px;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
            }

            .blue-text {
              color: #3cb2b9;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
            }

            h2,
            p {
              font-family: "Montserrat";
            }

            .btn-status {
              padding: 0px;
              width: 108px;
              height: 24px;
              border-radius: 40px;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 900;
              font-size: 12px;
              line-height: 21px;
              color: #ffffff;
            }

            .btn-show-edit {
              margin-top: 16px;
              width: 106px;
              height: 30px;
              background: var(--Brand-Colors-Primary-Color);
              border-radius: 25px;
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.514286px;
              color: #ffffff;
            }
          }

          .car-data {
            padding-left: 12px;

            .car-data-item {
              padding: 0 8px;
              align-items: center;
              p {
                margin: 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
              }
              img {
                border-radius: unset;
                size: 12px;
              }
            }
          }

          .MuiPaper-root {
            border: 1px solid #3cb2b9 !important;
            border-radius: 11px 11px 11px 8px !important;
          }

          .document-status {
            margin: 12px 0 0 6px !important;
            width: 94%;
          }

          .car-image {
            display: flex;
            height: 100%;
            background-color: #f9f9f9;

            img {
              max-height: 180px;
              width: 100%;
              height: auto;
              object-fit: cover;
              border-radius: 8px 8px 0 0;
            }

            .empty-image {
              width: 112px;
              object-fit: contain;
            }
          }
        }

        .btn-orange {
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 25px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          align-self: center;
          width: 140px;
        }

        .btn-blue {
          background-color: #3cb2b9;
          width: 140px;
        }

        .dropdown-divider {
          background: #e0e0e0;
          width: 84%;
        }
      }
    }

    .card::-webkit-scrollbar {
      width: 8px;
      height: 100%;
    }

    .card::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #f2f2f2;
      width: 8px;
      margin: 30px 0;
    }

    .card::-webkit-scrollbar-thumb {
      background: #3cb2b9;
      width: 8px;
      border-radius: 4px;
    }
  }

  .btn-container {
    margin: 24px 0;

    .btn-add {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 311px;
      height: 52px;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      p {
        margin: 0;
        padding: 0 6px 0 4px;
      }

      img {
        width: 28px;
      }
    }
  }

  .btns {
    display: flex;
    text-align: center;
    justify-content: space-around;

    a,
    button {
      margin-bottom: 12px;
    }
  }

  .car-hosts-msg {
    background: #ffffff;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;
    width: 94%;
    display: flex;
    max-width: 470px;

    img {
      width: 84px;
      height: 81px;
      margin: 8px 16px;
    }

    .hosts-msg-container {
      padding: 12px 0;
      h5 {
        color: #195154;
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }

      a {
        color: inherit;
        letter-spacing: 0.514286px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;

        img {
          margin: 0 0 0 10px;
          width: 20px;
          height: 20px;
        }
      }

      p,
      h5,
      a {
        font-family: "Montserrat";
        padding-top: 12px;
      }
    }
  }

  .cars-empty-list {
    text-align: center;
    padding: 60px 0 10px 0;
    img {
      width: 274px;
    }
    p {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #3cb2b9;
      padding: 32px 0 0 0;
      max-width: 360px;
    }
  }

  // nada
  // .MuiPaper-root {
  //   border: 1px solid #3cb2b9 !important;
  //   border-radius: 11px 11px 11px 8px !important;
  // }
}

.a-cars-container-des {
  display: none;
}

@media only screen and (min-width: 512px) {
  .a-cars-container-des {
    .join-container {
      .swiper-pagination {
        .swiper-pagination-bullet {
          background-color: #7b7b7b;
          border: 0px;
        }
      }
    }

    .car-hosts-msg {
      width: 94%;
      box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
      border-radius: 20px;

      img {
        width: 196px;
        height: 196px;
        margin: 0 70px;
      }

      .hosts-msg-container {
        padding: 12px 24px;
        h5 {
          color: #195154;
          font-weight: 700;
          font-size: 26px;
          line-height: 46px;
        }

        p {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #3cb2b9;
        }

        a {
          color: inherit;
          letter-spacing: 0.514286px;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;

          img {
            margin: 0 0 0 10px;
            width: 20px;
            height: 20px;
          }
        }

        p,
        h5,
        a {
          font-family: "Montserrat";
          padding-top: 12px;
        }
      }
    }

    .cars-empty-list {
      text-align: center;
      padding: 60px 0 10px 0;
      img {
        width: 444px;
      }
      p {
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #3cb2b9;
        padding: 60px 0;
      }
      .btn-container {
        margin: 24px 0;

        .btn-add {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          width: 311px;
          height: 52px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          p {
            margin: 0;
            padding: 0 6px 0 4px;
            color: #ffffff;
          }

          img {
            width: 28px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1130px) {
  .a-cars-container {
    display: none;
  }

  .a-cars-container-des {
    display: flex;
    flex-flow: column;
    align-items: center;

    .join-container {
      width: 1110px;
      margin: 70px 0 32px;

      .title-container {
        text-align: left;

        h1 {
          margin: 0;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 42px;
          line-height: 46px;
          color: #195154;
          padding: 44px 0 0 0;
        }
      }

      .btn-container {
        display: flex;
        align-items: flex-end;
        .btn-add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 180px;
          height: 30px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;

          p {
            margin: 0;
            padding: 0 6px 0 4px;
          }
        }
      }
    }

    .reservation-card {
      margin-bottom: 90px;

      .empty_cars {
        width: 540px;
        height: 413px;
      }

      .empty-text {
        line-height: 30px;
        text-align: center;
        color: #3cb2b9;
        width: 100%;
      }

      .empty-text,
      .empty-btn-orange {
        font-size: 20px;
        font-family: "Montserrat";
        font-weight: bold;
      }

      .empty-btn-orange {
        width: 285px;
        height: 52px;
        line-height: 25px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        color: #ffffff;
        margin: 32px auto 0px;
        display: block;
      }

      .car-card {
        .list-group {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .list-group-item {
            width: 100%;
            display: flex;
            align-items: flex-start;
            border: 0px;
            justify-content: unset;
            margin: 12px;
            padding: 0;
            align-items: center;
            box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
            border-radius: 8px;

            .car-image {
              width: 315px;
              height: 100%;
              background-color: #f9f9f9;

              img {
                max-height: 180px;
                width: 315px;
                object-fit: cover;
                border-radius: 8px 0 0 8px;
              }

              .empty-image {
                width: 112px;
                object-fit: contain;
              }
            }

            .reservation-text {
              margin-left: 16px;

              h2 {
                margin-top: 10px;
                font-weight: 800;
                color: inherit;
                font-size: 18px;
                line-height: 22px;
                margin-top: 0;
              }

              p {
                font-weight: normal;
                color: #333333;
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
              }

              h2,
              p {
                font-family: "Montserrat";
                margin-bottom: 8px;
              }

              .blue-text {
                color: #3cb2b9;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
              }

              .btn-status {
                padding: 0px;
                width: 108px;
                height: 24px;
                border-radius: 40px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 900;
                font-size: 12px;
                line-height: 21px;
                color: #ffffff;
              }
            }

            .document-status {
              .yellow-text {
                color: #ffab03 !important;
                font-weight: 500 !important;
              }

              .red-text {
                color: #eb5757 !important;
                font-weight: 500 !important;
              }
            }

            .car-data {
              padding-left: 8px;

              .car-data-item {
                padding: 0 8px;
                align-items: center;

                p {
                  margin: 0;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                }
              }
            }

            .btn-container {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              a,
              button {
                margin-top: 16px;
              }
            }

            .MuiPaper-root {
              border: 1px solid #3cb2b9 !important;
              border-radius: 11px 11px 11px 8px !important;
            }
          }

          .btn-orange {
            background: var(--Brand-Colors-Primary-Color);
            border-radius: 25px;
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            align-self: center;
            width: 180px;
          }

          .btn-blue {
            background-color: #3cb2b9;
            width: 180px;
          }

          .dropdown-divider {
            background: #e0e0e0;
            width: 93%;
          }
        }
      }

      .card::-webkit-scrollbar {
        width: 8px;
        height: 100%;
      }

      .card::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #f2f2f2;
        width: 8px;
        margin: 30px 0;
      }

      .card::-webkit-scrollbar-thumb {
        background: #3cb2b9;
        width: 8px;
        border-radius: 4px;
      }
    }
  }
}
