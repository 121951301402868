.carousel-benefits-container {
  background-color: var(--Utility-Colors-Primary-Background);
  padding: 40px 4% 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    color: var(--Utility-Colors-Primary-Text);
    text-align: center;
    font-family: "Montserrat";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29.26px;
    margin-bottom: 8px;
  }

  p {
    color: var(--Utility-Colors-Secondary-Text);
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 885px;
  }

  .swiper {
    max-width: 1400px;
    margin-top: 24px;
    .swiper-slide {
      .benefit-card {
        position: relative;
        border-radius: 13.333px;
        background: transparent;
        display: flex;
        flex-direction: column;
        max-width: 360px;
        height: 232px;
        text-decoration: none;
        margin: 20px 0;
        align-items: center;

        .benefit-image {
          width: 86px;
          margin-bottom: 12px;
          height: 86px;
        }

        .disabled {
          filter: blur(8px) grayscale(80%);
        }

        h4 {
          color: var(--Utility-Colors-Primary-Text);
          text-align: center;
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 19.5px;
          margin-bottom: 12px;
        }

        .subtitle {
          color: var(--Utility-Colors-Secondary-Text);
          text-align: center;
          font-family: "Montserrat";
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
        }
      }
    }

    .swiper-pagination {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .swiper-pagination-bullet-active {
        background-color: var(--Utility-Colors-Primary-Text) !important;
        width: 22px;
        height: 22px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .carousel-benefits-container {
    display: none;
  }
}
