.profile {
  background-color: var(--Utility-Colors-Primary-Background);

  .profile-container {
    padding: 40px 0 0;

    .profile-data {
      width: 313px;
      margin: 0 auto;

      .welcome-profile {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 201px;
        margin: 0 auto 40px;

        .welcome-text {
          text-align: center;

          h2 {
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            font-weight: 700;
            color: var(--Utility-Colors-Primary-Text);
            margin: 0 0 4px;
          }

          p {
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 18px;
            color: var(--Utility-Colors-Secondary-Text);
            white-space: nowrap;
            margin-bottom: 27px;
          }
        }

        .images {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .hover-profile {
            width: 50px;
            height: 50px;
            top: 23%;
            left: 23%;
            border-radius: 50%;
            position: absolute;
            z-index: 1;
          }

          .profile-img {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }

      .profile-info {
        flex-flow: column;

        .profile-generic-info {
          margin: 0 auto 24px;
          padding-left: 5px;

          label {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--Utility-Colors-Primary-Text);
          }

          .input-text-large {
            width: 293px;
            margin-top: 16px;
            border-bottom: 1px solid #bdbdbd;

            p {
              font-family: "Montserrat";
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: var(--Utility-Colors-Secondary-Text);
              margin: 0 0 6px 14px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .verifying {
            display: flex;
            justify-content: space-between;

            span {
              display: flex;
              font-weight: 600;
              color: #ffab03;
            }

            .validated {
              color: rgb(33, 150, 83);
            }

            a {
              font-weight: 500;
              text-decoration-line: underline;
              color: #2f80ed;
            }

            a,
            span {
              font-family: "Montserrat";
              font-size: 12px;
              line-height: 24px;
            }

            img {
              margin-right: 5px;
            }
          }
        }
      }

      .profile-info-form {
        display: flex;
        flex-flow: wrap;

        .profile-generic-info {
          padding-left: 5px;
          margin: 0 auto 24px;

          label {
            color: var(--Utility-Colors-Primary-Text);
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            gap: 10px;
          }

          .change-links {
            font-weight: 500;
            text-decoration-line: underline;
            color: #2f80ed;
            font-family: "Montserrat";
            font-size: 12px;
            line-height: 24px;
          }

          .input-text-large {
            margin-top: 16px;

            input {
              width: 312px;
              height: 38px;
              background: var(--Utility-Colors-Secondary-Background);
              border: 1px solid #bdbdbd;
              box-sizing: border-box;
              border-radius: 40px;
              font-family: "Montserrat";
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              padding: 7px 0 7px 16px;
              color: var(--Utility-Colors-Secondary-Text);
            }

            input:active,
            input:hover,
            input:focus {
              color: #333333;
              border: 1px solid #828282;
              box-shadow: 0px 4px 18px rgba(209, 217, 230, 0.8);
              outline: none !important;
            }
          }

          .verifying {
            display: flex;
            justify-content: space-between;

            span {
              font-weight: 600;
              color: #ffab03;
            }

            a {
              font-weight: 500;
              text-decoration-line: underline;
              color: #2f80ed;
            }

            .validated {
              color: rgb(33, 150, 83);
            }

            a,
            span {
              font-family: "Montserrat";
              font-size: 12px;
              line-height: 24px;
            }

            img {
              margin-right: 5px;
            }
          }
        }
      }

      .btn-edit {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 187px;
        height: 38px;
        margin: 0 auto;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;

        p {
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          margin: 0;
        }

        img {
          width: 16px;
        }
      }

      .btn-save {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 187px;
        height: 38px;
        margin: 0 auto;
        background: #219653;
        border-radius: 40px;

        p {
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          margin: 0;
        }

        img {
          width: 16px;
        }
      }
    }

    .invite-friends {
      display: none;
    }
  }

  .profile-documents-container {
    width: 350px;
    margin: 32px auto 40px auto;

    h2 {
      text-align: start;
      width: 100%;
      font-family: "Montserrat";
      font-weight: bold;
      color: var(--Utility-Colors-Primary-Text);
      margin: 0 0 14px;
      font-size: 18px;
    }

    .document-verification {
      border-radius: 4px 4px 4px 4px;
      display: flex;
      max-width: 354px;

      .verification-button {
        border-radius: 0 4px 4px 0;
        background: #507ded;
        color: #fff;
        width: 100%;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .uploader__widget-base {
      height: 97px;
      position: relative;
      background: var(--Utility-Colors-Secondary-Background);

      .uploader__widget-base__children {
        display: flex;
        align-items: center;

        .uploader__main-screen__file-list__inner {
          height: 77px;
          padding: 0;
          .uploader__submitted-file {
            height: 60px;
            .uploader__submitted-file__container {
              .uploader__submitted-file__inner {
                height: 77px;
                .uploader__submitted-file__text {
                  height: 77px;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }

        .btn--upload {
          margin: 0;
          border: none;
          font-size: 14px;
          color: var(--Utility-Colors-Primary-Text);
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          background: var(--Utility-Colors-Secondary-Background);
        }

        .btn--upload:hover {
          background-color: #ecf7f8 !important;
          color: #000 !important;
        }
      }
    }

    .divider {
      border-bottom: 1px solid #e0e0e0;
      margin: 12px 0;
      width: 100%;
    }

    h3 {
      text-align: start;
      font-family: "Montserrat";
      font-weight: 800;
      color: var(--Utility-Colors-Primary-Text);
      margin: 0;
      font-size: 20px;
    }

    ul {
      li {
        color: var(--Utility-Colors-Secondary-Text);
      }
    }

    p {
      color: var(--Utility-Colors-Secondary-Text);
      margin: 0;
    }

    .document-type {
      flex-direction: column;

      .status-container {
        padding: 4px 0;
        .revision-status {
          color: #ffab03;
          font-weight: 600;
        }
        .retry-status {
          color: #eb5757;
          font-weight: 600;
        }
        .approve-status {
          font-size: 16px;
          color: #195154;
        }
        .revision-status,
        .retry-status {
          margin: 0;
          font-size: 15px;
        }
        .expiration-warning {
          color: #ffab03;
          font-weight: 600;
          font-size: 14px;
        }
        svg {
          font-size: 22px;
          padding-right: 4px;
        }
      }
    }
    .not-uploaded {
      margin: 8px -6px 0 16px;
    }

    .delete-file-btn {
      color: #3cb2b9;
      font-weight: bolder;
      margin-left: 16px;
      cursor: pointer;
    }

    .delete-file-btn:hover {
      color: #71d8dd;
    }

    .MuiStepConnector-root {
      display: none;
    }
  }
}

.moffin-modal {
  .modal-dialog {
    margin: 200px auto 0 !important;
    .modal-content {
      width: 500px;
      height: 500px;
      .moffin-page {
        width: 500px;
        height: 500px;
      }
    }
  }
}

.modal-kyc {
  width: 100%;
  height: 100%;
  padding-top: 100px;

  .modal-content {
    width: 90%;
    max-width: 400px;
    height: 600px;
    margin: 0 auto;

    .modal-header {
      display: flex;
      justify-content: flex-end;

      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: #ff7c45;
        border-radius: 40px;
        border: 0;

        img {
          width: 24px;
        }
      }
    }

    .kyc-iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.m-auto {
  margin: 0 auto;
}

@media only screen and (min-width: 991px) {
  .profile {
    .profile-container {
      display: flex;
      justify-content: space-between;
      padding-top: 100px;
      max-width: 1108px;
      margin: 0 auto;

      .profile-data {
        display: flex;
        flex-flow: column;
        width: 540px;
        margin: 6px;

        .welcome-profile {
          margin: 0;
          width: 363px;
          flex-flow: row-reverse;
          align-items: flex-start;
          justify-content: center;
          order: 1;

          .images {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;

            .profile-img {
              width: 94px;
              height: 94px;
            }

            .hover-profile {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              position: absolute;
            }
          }

          .welcome-text {
            text-align: start;

            h2 {
              font-size: 42px;
              line-height: 46px;
            }

            p {
              font-size: 22px;
              line-height: 46px;
            }
          }
        }

        .file-input {
          order: 2;
          width: 255px;

          p {
            text-align: start;
          }
        }

        .profile-info {
          border-top: 1px solid #e0e0e0;
          padding-top: 24px;
          order: 4;
          margin: 0;
          display: flex;
          flex-flow: wrap;

          .profile-generic-info {
            .input-text-large {
              width: 255px;
            }
          }
        }

        .profile-info-form {
          border-top: 1px solid #e0e0e0;
          padding-top: 24px;
          order: 4;
          margin: 0;
          display: flex;
          flex-flow: wrap;

          .profile-generic-info {
            .input-text-large {
              input {
                width: 255px;
              }
            }
          }
        }

        .btn-edit {
          order: 3;
          margin: 0 0 28px 0;
          align-self: flex-end;
        }

        .btn-save {
          order: 3;
          margin: 0 0 28px 0;
          align-self: flex-end;
        }
      }

      .invite-friends {
        display: block;
        margin-left: 0px;

        .card {
          width: 445px;
          height: 472px;
          background: var(--Utility-Colors-Secondary-Background);
          box-shadow: 2px 8px 4px 0px rgba(0, 0, 0, 0.25);
          border-radius: 20px;

          .card-body {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            h3 {
              font-weight: 600;
              font-size: 24px;
              line-height: 46px;
              color: var(--Utility-Colors-Primary-Text);
              margin-top: 0;
            }

            p {
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: var(--Utility-Colors-Secondary-Text);
            }

            h3,
            p {
              font-family: "Montserrat";
              text-align: center;
            }

            .inv-img {
              width: 362px;
              height: 230px;
            }

            .btn-copy {
              width: 160px;
              height: 38px;
              margin: 28px 0;
              background: var(--Brand-Colors-Primary-Color);
              border-radius: 40px;
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 14px;
              line-height: 19px;
              color: #ffffff;
            }

            .social-media {
              display: flex;
              justify-content: space-between;
              width: 214px;

              .linkedin {
                width: 24px;
              }
            }
          }
        }
      }
    }
    .profile-documents-container {
      width: 95%;
      display: flex;
      flex-wrap: wrap;
      max-width: 1108px;

      h2 {
        font-size: 22px;
        line-height: 24px;
        width: 100%;
        text-align: start;
      }

      .document-type {
        max-width: 1108px;
        flex-direction: row;
        align-items: center;
        padding: 12px 0 12px 0;

        .status-container {
          align-items: center;
          .revision-status,
          .retry-status {
            padding: 0 0 0 2px;
          }
          svg {
            padding: 0 2px 0 4px;
          }
        }
      }
    }
  }

  .modal-kyc {
    width: 100%;
    height: 100%;
    padding-top: 100px;

    .modal-content {
      width: 500px;
      height: 600px;
      max-width: 500px;

      .kyc-iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
