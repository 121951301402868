.un-win {
  background-color: var(--Utility-Colors-Primary-Background);
  padding: 60px 0;
  margin: 0;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1577px;
    margin: 0 auto;
    padding: 0;
    width: 100%;

    .container-text {
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .order-bottom-right {
      order: unset;
    }

    .order-top-right {
      order: 1;
    }

    .order-top-left {
      order: 1;
    }

    img {
      max-width: 400px;
    }
    .clarity-image {
      max-width: 500px;
    }

    .image-container {
      width: 80%;
      padding: 28px 0;
      display: flex;
      justify-content: center;
      .image {
        width: 100%;
      }
    }

    h3,
    h4,
    p,
    h5 {
      text-align: start;
    }

    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: var(--Utility-Colors-Primary-Text);
    }

    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: var(--Utility-Colors-Primary-Text);
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: var(--Utility-Colors-Primary-Text);
      max-width: 500px;
    }

    .btn-orange {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      margin: 8% auto;
      font-weight: 700;
      color: #fff;
      width: 248px;
      height: 52px;
      font-family: "Montserrat";
    }
  }
}

@media only screen and (min-width: 900px) {
  .un-win {
    display: flex;
    align-items: center;

    .content {
      width: 81%;
      display: flex;
      flex-direction: row;
      flex-flow: row-reverse;
      align-content: flex-start;
      justify-content: center;
      gap: 20px;

      .order-bottom-right {
        order: 1;
      }

      .order-top-left {
        order: 0;
      }

      .container-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 620px;
        align-items: flex-start;

        h3 {
          line-height: 50px;
        }

        div {
          p {
            max-width: 445px;
          }
        }
      }

      .image-container {
        width: 50%;
        max-width: 520px;
      }

      h3,
      h4,
      p,
      h5 {
        text-align: start;
      }

      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 42px;
        line-height: 29px;
      }

      h4 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
      }

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        max-width: 500px;
      }

      .btn-orange {
        margin: 6% 0;
        width: 250px;
        height: 52px;
      }
    }
  }
}
