.in-progress-container {
  min-height: 60vh;
  background: var(--Utility-Colors-Primary-Background);

  .content {
    text-align: center;
    h3 {
      margin: 24px auto;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 32px;
      line-height: 46px;
      color: var(--Utility-Colors-Primary-Text);
    }

    p {
      font-size: 20px;
      font-family: "Montserrat";
      margin: 0 0 24px 0;
      line-height: 30px;
      color: var(--Utility-Colors-Secondary-Text);
    }
  }
}

@media only screen and (min-width: 992px) {
  .in-progress-container {
    .content {
      h3 {
        font-size: 42px;
      }
    }
  }
}
