.un-find {
  background-color: var(--Utility-Colors-Primary-Background);
  position: relative;

  #un_background_des {
    display: none;
  }

  .un-title {
    display: flex;
    width: 100%;
    align-items: center;
    flex-flow: column;
    position: absolute;
    top: 0;

    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #768295;
    }
  }

  .un-form {
    position: relative;
    width: 100%;
    border: 0px;
    background-color: transparent;
    z-index: 1;

    .card-body {
      margin: 20px auto 0;
      border-radius: 60px;
      padding: 35px 25px 0;
      height: 403px;
      box-shadow: 0px 8px 25px rgba(73, 73, 73, 0.24);
      width: 347px;
      background-color: #fff;

      .where-container {
        width: 100%;
        position: relative;
        margin-bottom: 19px;
      }

      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: 22px auto 15px;
        color: #fff;
        width: 248px;
        height: 52px;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
      }

      label,
      a {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
      }

      .ant-picker {
        height: 40px;
      }

      .ant-picker,
      .MuiFormControl-root input,
      .pac-target-input,
      .dropdown-toggle {
        background: #ffffff;
        padding: 9px;
        background-color: #f7f7f7;
        border: 0px !important;
        box-sizing: border-box;
        border-radius: 6px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;

        .bs-caret {
          display: none;
        }
      }

      input::placeholder {
        color: #000;
      }

      .dates {
        display: flex;
        flex-direction: column;
        .un-desde,
        .un-hasta {
          margin-bottom: 19px;
          .form-group {
            input {
              box-shadow: none;
              text-align: start;
            }
          }
          .dropdown {
            border-radius: 6px;
            height: 38px;
            width: 152px;
            background-color: #f7f7f7;
            padding: 9px 5px;

            .time-icon {
              display: none;
            }
            .MuiOutlinedInput-notchedOutline {
              border: 0px;
            }
          }
        }

        .dropdown-menu {
          top: 30px;
          border-radius: 0px 0px 10px 10px;
        }

        .un-date {
          width: 48% !important;
          margin-top: 4px;
        }

        .MuiFormControl-root {
          width: 46%;
          .MuiOutlinedInput-input {
            height: 40px;
          }
          .MuiOutlinedInput-notchedOutline {
            border: 0px;
          }
        }

        .datepicker {
          width: 48%;
          margin-right: 11px;
          input {
            width: 100%;
            color: black;
          }
        }

        .un-date {
          button {
            color: black !important;
          }
        }
      }
    }
  }

  .un-car {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 75%;
  }

  .main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 30px 0 0;

    .main-information {
      width: 269px;
      margin-bottom: 20px;

      .title,
      .subtitle {
        font-family: "Montserrat";
        color: var(--Utility-Colors-Primary-Text);
      }

      .title {
        font-weight: 600;
        font-size: 36px;
        line-height: 43px;
      }

      .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .home-main-image {
      max-width: 673px;
    }
  }

  .un-background {
    max-height: 756px;
  }
}

.steps-container {
  background-color: var(--Utility-Colors-Primary-Background);
}

.un-info {
  padding: 0;

  .row {
    width: 100%;
    margin: 0;
    padding: 10% 4%;

    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: var(--Utility-Colors-Primary-Text);
      text-align: center;
    }

    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: var(--Utility-Colors-Primary-Text);
      text-align: center;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: var(--Utility-Colors-Primary-Text);
    }

    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #333333;
    }

    .pink {
      color: var(--Brand-Colors-Primary-Color);
      text-decoration: underline;
    }
  }

  .un-sec {
    background-color: var(--Utility-Colors-Primary-Background);
    margin-bottom: 0;

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        max-width: 429px;
      }

      .content-text {
        max-width: 429px;
        padding: 0;
        text-align: justify;

        .card {
          background-color: transparent;
          border: 0px;
          max-width: 370px;

          .card-body {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            background-color: transparent;

            .card-title-text {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: #373a3c;
              margin: 10px;
            }

            .list-right,
            .list-left {
              margin-bottom: 2px;
              padding-left: 0px;

              li {
                display: flex;
                gap: 20px;
                list-style: none;
                font-family: "Montserrat";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--Utility-Colors-Primary-Text);
                margin-bottom: 15px;
              }
            }

            a {
              text-decoration: underline;
              color: #3cb2b9;
              margin: 10px;
            }
          }
        }
      }

      .image {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .un-sec {
    .content {
      max-width: 1577px;
      margin: 0 auto;

      .image-container {
        padding: 28px 0;
      }

      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: 8% auto;
        color: #fff;
        width: 248px;
        font-weight: 700;
        height: 52px;
        font-family: "Montserrat";
      }
    }
  }

  .un-lote {
    .content {
      max-width: 1577px;
      margin: 0 auto;

      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: 8% auto;
        color: #fff;
        width: 248px;
        height: 52px;
        font-family: "Montserrat";
      }

      .text-container {
        justify-content: center;
        display: flex;
        flex-flow: column;
      }

      .img-container {
        display: flex;
        justify-content: center;

        img {
          max-width: 476px;
        }
      }
    }
  }
}

.home-carousel {
  display: flex;
  background-color: var(--Utility-Colors-Primary-Background);
  .carousel-suggestions-container {
    .car-image {
      div {
        height: 180px;
      }
    }
  }
}

strong {
  font-weight: 600;
}

@media only screen and (min-width: 500px) {
  .un-info {
    .un-sec {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .un-info {
    padding: 0;

    .un-sec {
      .content {
        gap: 56px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        img {
          max-width: 500px;
        }

        .content-text {
          order: 2;

          .btn-orange {
            margin: 43px 0 0;
          }
        }

        h3,
        h4 {
          text-align: left;
        }
      }
    }

    .un-sec {
      .content {
        width: 81%;
      }
    }

    .un-lote {
      .content {
        display: flex;
        flex-flow: row;
        align-content: flex-start;

        h3,
        h4,
        p,
        h5 {
          text-align: start;
        }

        .btn-orange {
          margin: 6% 0;
          width: 156px;
          height: 52px;
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .un-find {
    position: relative;

    .un-title {
      display: flex;
      width: 60%;
      right: 0;
      top: 9%;
    }

    .un-form {
      width: 100%;
      right: 0;

      .card-body {
        display: flex;
        flex-wrap: wrap;
        margin: 62px auto 0;
        width: 80%;
        max-width: 1012px;
        height: 286px;
        background-color: #fff;

        .where-container {
          width: 100%;
          position: relative;
        }

        .btn-orange {
          margin: 3% auto;
        }

        label,
        a {
          line-height: 20px;
        }

        .dates {
          display: flex;
          flex-direction: row;
          .un-container-date {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;

            .un-desde,
            .un-hasta {
              margin-top: 0;
              width: 48%;
            }
          }

          .date {
            width: 50% !important;
            height: 100%;
          }

          .un-date {
            height: 100%;
            width: 50% !important;
            margin-top: 0px;
          }
        }
      }
    }

    .un-car {
      bottom: 5%;
      width: 60%;
      max-width: 1068px;
    }

    .main-section {
      align-items: flex-start;
      flex-direction: row;
      text-align: left;
      margin: 40px 0 0;

      .main-information {
        width: 50%;
        padding: 40px 0 0 20px;
        .title {
          font-weight: 700;
          font-size: 36px;
        }

        .subtitle {
          font-weight: 600;
          font-size: 22px;
          line-height: 24px;
        }
      }

      .home-main-image {
        width: 50%;
        max-width: 673px;
        height: auto;
      }
    }

    .un-background {
      max-height: 756px;
    }
  }
}

@media only screen and (min-width: 900px) {
  .un-find {
    #un_background {
      display: none;
    }

    #un_background_des {
      display: block;
    }

    .home-main-image {
      height: 72vh;
      object-position: 50% 93%;
    }
  }

  .un-info {
    .un-sec {
      .content {
        gap: 126px;
      }
    }
  }
}

@media only screen and (min-width: 1150px) {
  .un-find {
    position: relative;

    .un-title {
      display: flex;
      width: 60%;
      right: 0;
      top: 125px;

      h1 {
        font-size: 54px;
        line-height: 66px;
      }

      p {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .un-form {
      .card-body {
        width: 100%;
        height: 98px;
        padding: 0 10px 0 35px;
        display: flex;
        align-items: center;

        .where-container {
          width: 260px;
          margin-right: 10px;
          position: relative;
          margin-bottom: 0;
        }

        .btn-orange {
          margin: 0 0 0 30px;
          width: 40px;
          height: 40px;

          img {
            width: 25px;
          }
        }

        .un-container-date {
          display: flex;
          justify-content: space-between;
          margin-top: 0px;
          width: 600px;

          .MuiFormControl-root {
            width: 46%;
            .MuiOutlinedInput-input {
              height: 40px;
            }
            .MuiOutlinedInput-notchedOutline {
              border: 0px;
            }
          }

          .un-desde,
          .un-hasta {
            width: 50%;
            padding: 0 10px;
            border-left: 1px solid #3cb2b9;
          }
        }

        .dates {
          .un-desde,
          .un-hasta {
            margin-bottom: 0px;
          }
        }
      }
    }

    .un-car {
      bottom: 5%;
      width: 60%;
      max-width: 1068px;
    }

    .main-section {
      max-width: 1440px;
      margin: 50px auto 0;
      justify-content: space-around;

      .main-information {
        width: 45%;
        .title {
          font-size: 50px;
          line-height: 60px;
        }

        .subtitle {
          font-size: 36px;
          line-height: 44px;
        }
      }

      .home-main-image {
        width: 40%;
        max-width: 673px;
        height: auto;
      }
    }

    .un-background {
      max-height: 756px;
    }
  }

  .un-info {
    padding: 0;

    .row {
      padding: 5% 10%;

      h3 {
        font-size: 42px;
        line-height: 46px;
      }

      h4 {
        font-size: 20px;
        line-height: 30px;
      }

      h5 {
        font-size: 18px;
        line-height: 24px;
      }

      .btn-orange {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .home-carousel {
    padding: 67px 0 121px;
  }
}
.time-icon {
  margin-left: 5px;
}
