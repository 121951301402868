.un-steps {
  background-color: var(--Utility-Colors-Primary-Background);
  display: flex;
  flex-direction: column;
  padding: 50px 10px 50px;

  .steps-title-container {
    display: flex;
    flex-direction: column;
    text-align: center;

    h3 {
      color: var(--Utility-Colors-Primary-Text);
      font-family: "Montserrat";
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
    }

    p {
      color: var(--Utility-Colors-Primary-Text);
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }
  }

  .information-container {
    display: flex;
    flex-direction: column;
    .top-container {
      order: 2;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      position: relative;

      img {
        width: 80%;
        max-width: 396px;
      }
    }

    .bottom-container {
      order: 1;
      padding: 10% 0 20px;

      .stepper {
        margin: 0px;

        .step {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: 10px;

          img {
            width: 38px;
            height: 38px;
          }

          .step-info {
            h3 {
              font-family: "Montserrat";
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: var(--Utility-Colors-Primary-Text);
            }

            p {
              font-family: "Montserrat";
              font-weight: 400;
              width: 248px;
              font-size: 16px;
              line-height: 24px;
              color: var(--Utility-Colors-Secondary-Text);
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .un-steps {
    padding: 50px 30px;
    margin: 0 auto;
    max-width: 1440px;

    .steps-title-container {
      display: flex;
      flex-direction: column;
      text-align: center;

      h3 {
        font-size: 42px;
      }
    }
    .information-container {
      flex-direction: row;
      justify-content: space-around;

      .top-container {
        order: 1;
        img {
          max-width: 576px;
        }
      }

      .bottom-container {
        order: 2;
        max-width: 568px;

        .stepper {
          flex-direction: column;
          margin: 0 auto;

          .step {
            justify-content: flex-start;
            .step-info {
              p {
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }
}
