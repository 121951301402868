.carousel-destinations-container {
  background-color: var(--Utility-Colors-Primary-Background);
  padding: 40px 4% 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    color: var(--Utility-Colors-Primary-Text);
    text-align: center;
    font-family: "Montserrat";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
  }

  p {
    color: var(--Utility-Colors-Primary-Text);
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 50px;
    line-height: 26px;
    max-width: 885px;

    .pink {
      color: var(--Brand-Colors-Primary-Color);
    }
  }

  .swiper {
    max-width: 1400px;
    .swiper-slide {
      .destination-card {
        position: relative;
        border-radius: 13.333px;
        background: transparent;
        display: flex;
        flex-direction: column;
        width: 360px;
        height: 326.667px;
        text-decoration: none;
        margin: 20px 0;

        .destination-image {
          width: 100%;
          margin-bottom: 22px;
          height: 210px;
          border-radius: 13.333px;
        }

        .disabled {
          filter: blur(8px) grayscale(80%);
        }

        h4 {
          color: var(--Utility-Colors-Primary-Text);
          text-align: left;
          font-family: "Montserrat";
          font-size: 26.667px;
          font-style: normal;
          font-weight: 600;
          line-height: 25px;
          margin-bottom: 16px;
        }

        .btn-status-destination {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 13.333px;
          background: var(--Utility-Colors-Primary-Text);
          width: 69.167px;
          height: 24.167px;
          color: var(--Utility-Colors-Primary-Background);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px;
        }

        .text-status-destination {
          color: var(--Brand-Colors-Black-color-fix);
          position: absolute;
          border-radius: 30px;
          padding: 2px 13px;
          right: 20px;
          top: 15px;
          background-color: rgb(255, 255, 255, 0.5);
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet-active {
        background-color: #000 !important;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .carousel-destinations-container {
    h3 {
      font-size: 50px;
    }

    p {
      font-size: 36px;
      font-weight: 600;
      line-height: 43.88px;
    }
  }
}
