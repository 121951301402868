.benefits-container-unhomepage {
  background-color: var(--Utility-Colors-Primary-Background);
  padding: 60px 23px 68px;

  .title-container {
    text-align: center;

    .title {
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: var(--Utility-Colors-Primary-Text);
    }
    p {
      color: var(--Utility-Colors-Primary-Text);
    }
  }

  .list-benefits {
    display: flex;
    flex-flow: wrap;
    max-width: 1250px;
    justify-content: space-evenly;

    .benefit {
      display: flex;
      flex-direction: column;
      gap: 13px;
      background-color: var(--Utility-Colors-Secondary-Background);
      width: 100%;
      border-radius: 16px;
      margin-top: 40px;

      img {
        width: 100%;
        height: 170px;
        margin-bottom: 10px;
      }

      .benefit-information {
        display: flex;
        flex-direction: column;
        padding: 0 15px 20px;
        gap: 10px;

        h3 {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 24px;
          color: var(--Utility-Colors-Primary-Text);
          margin-bottom: 0;
        }

        p {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 20px;
          margin-bottom: 0;
          color: var(--Utility-Colors-Primary-Text);
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .benefits-container-unhomepage {
    padding: 63px 23px 93px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .title-container {
      text-align: center;

      .title {
        font-size: 42px;
        line-height: 46px;
      }

      p {
        font-size: 18px;
        line-height: 30px;
        max-width: 880px;
        margin: 0 auto;
      }
    }

    .list-benefits {
      .benefit {
        flex-direction: column;
        width: 380px;
        margin: 55px 17px;

        .benefit-information {
          p {
            width: 310px;
          }
        }
      }
    }
  }
}
