.comercial-signup-container {
  display: flex;
  flex-flow: column;
  align-items: center;

  .join-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 40px;

    .title-container {
      h1 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #195154;
        margin-top: 0px;
      }
    }

    .info-container,
    .title-container {
      text-align: center;
      width: 312px;
      margin: 0 auto;
    }

    .info-container {
      a,
      p {
        font-family: "Montserrat";
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }

      p {
        color: #000000;
      }
    }

    img {
      width: 311.28px;
      height: 239.22px;
    }

    a {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }

  .comercial-signup-form {
    padding: 40px 32px 50px;
    max-width: 478px;
    width: 100%;

    .input-form {
      display: flex;
      flex-flow: column;
      margin-bottom: 24px;

      .dropdown {
        width: 100% !important;
        font-family: "Montserrat";
        font-size: 16px;
        line-height: 24px;

        .dropdown-menu {
          .dropdown-item {
            white-space: pre-wrap;
          }
        }
      }

      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #373a3c;
      }

      input {
        background: #ffffff;
        padding: 8px 16px 9px;
        border: 1px solid #828282;
        border-radius: 40px;
      }

      .input-group {
        border-radius: 40px;

        input {
          height: 39px;
          border-right: 0px;
        }

        input:focus {
          box-shadow: none;
          border-color: #828282;
        }

        .input-group-addon {
          border: 1px solid #828282;
          background: #ffffff;
          border-radius: 0px 40px 40px 0px;
          border-left: 0px;
          height: 39px;
        }
      }

      input::placeholder {
        font-size: 14px;
        line-height: 24px;
        color: #828282;
      }

      input:focus,
      .input-group:focus-within {
        border-color: #828282;
        box-shadow: 0px 4px 18px rgba(209, 217, 230, 0.8);
      }

      input:active,
      input:hover,
      input:focus {
        outline: none !important;
      }

      p {
        font-size: 13px;
        line-height: 19px;
        color: #818a91;
      }

      a {
        text-decoration: underline;
      }

      input::placeholder,
      p {
        font-weight: normal;
      }

      label,
      p,
      input::placeholder {
        font-family: "Montserrat";
      }

      .dropzone-ui {
        width: 328px;
        height: 174px;
        background: #ecf7f8;
        border: 1px dashed #8f9cb2;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;

        .dz-ui-header {
          margin-top: 5px;
        }

        .dz-ui-label {
          font-family: "Monserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #596178;
        }

        .file-item-name {
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 12px;
          color: #373a3c;
        }
      }
    }

    .button-form {
      display: flex;
      justify-content: center;
      margin-top: 60px;

      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        width: 311px;
        height: 52px;
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        outline: none !important;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .comercial-signup-container {
    flex-flow: row;
    align-items: flex-start;
    justify-content: center;
    padding-top: 100px;
    .join-container {
      width: 600px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      .title-container {
        order: 1;
        h1 {
          font-size: 42px;
          line-height: 46px;
        }
      }
      .info-container,
      .title-container {
        text-align: start;
        width: 430px;
      }
      .info-container {
        order: 2;
        a,
        p {
          font-size: 20px;
          line-height: 30px;
        }
        br {
          display: none;
        }
      }
      img {
        order: 3;
        width: 444px;
        height: 341.22px;
        margin: 34px auto 0;
      }
    }
    .comercial-signup-form {
      max-width: 600px;
      .input-form {
        .number-input {
          width: 80%;
        }
        .lada {
          display: none;
        }
        .lada-des {
          display: block;
          height: 38px;
          margin: 0px 15px 0 0;
          .dropdown-toggle {
            background: #f2f2f2;
            border-radius: 4px;
          }
          .bs-caret {
            display: none;
          }
        }
        .dropzone-ui {
          width: 445px;
          .dz-ui-label {
            font-family: "Monserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 17px;
            color: #596178;
          }

          .file-item-name {
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 12px;
            color: #373a3c;
          }
        }
      }
      .button-form {
        justify-content: flex-end;
        .btn-orange {
          width: 160px;
          height: 38px;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
