.testimony-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 80px;
  width: 100%;
  margin: 0;
  padding: 70px 4% 20px;
  background-color: var(--Utility-Colors-Primary-Background);

  .testimony-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    align-items: center;

    .car {
      color: var(--Utility-Colors-Secondary-Text);
      text-align: center;
      font-family: "Montserrat";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .name {
      color: var(--Utility-Colors-Primary-Text);
      text-align: center;
      font-family: "Montserrat";
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 32px 0 6px;
    }

    .testimony-text {
      color: var(--Utility-Colors-Primary-Text);
      text-align: center;
      font-family: "Montserrat";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      max-width: 304px;
      margin-top: 10px;
    }
  }
}
