.main-ac-homepage {
  background-color: var(--Utility-Colors-Primary-Background);
  .ac-find {
    background-color: var(--Utility-Colors-Primary-Background);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 5%;

    .ac-title {
      display: flex;
      width: 100%;
      align-items: center;
      flex-flow: column;
      text-align: center;
      height: 307px;
      border-radius: 0px 0px 100px 100px;

      .btn-container {
        .btn-orange {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--Brand-Colors-Secondary-Color);
          border-radius: 40px;
          margin: 19px auto;
          color: #fff;
          width: 211px;
          height: 52px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        }
      }

      h1 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 39px;
        color: var(--Utility-Colors-Primary-Text);
      }

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--Utility-Colors-Primary-Text);
        height: 40px;
      }
    }

    .ac-background {
      max-width: 500px;
    }
  }
}

.benefits-container-unhomepage {
  .list-benefits {
    .benefit {
      padding: 16px;
      .benefit-information {
        padding: 0;
      }
    }
  }
}

.ac-info {
  background-color: var(--Utility-Colors-Primary-Background);
  padding: 2% 0;

  .row {
    margin: 0;
    width: 100%;
    padding: 5% 0;

    .signup {
      font-size: 20px;
      line-height: 30px;
    }

    .signup,
    h3 {
      color: var(--Utility-Colors-Primary-Text);
      text-align: center;
    }

    .singup,
    h4 {
      font-family: "Montserrat";
      font-weight: 600;
    }

    h3 {
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
    }

    h4 {
      font-size: 18px;
      line-height: 30px;
      color: var(--Utility-Colors-Primary-Text);
      text-align: center;
    }

    p {
      font-family: "Montserrat";
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--Utility-Colors-Primary-Text);
    }

    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #333333;
      margin: 0 0 10px;
    }

    .btn-orange {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--Brand-Colors-Secondary-Color);
      border-radius: 40px;
      margin: 8% auto;
      color: #fff;
      width: 248px;
      height: 52px;
      font-family: "Montserrat";

      font-weight: 600;
    }

    .ac-img-container {
      float: left;
      height: 24px;
      margin: 0 0 10px;
    }
  }

  .ac-win {
    background-color: var(--Utility-Colors-Primary-Background);

    .content {
      max-width: 1300px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .order-bottom-right {
        order: unset;
      }

      .order-top-right {
        order: 1;
      }
    }
  }
}

.steps-container {
  background-color: var(--Utility-Colors-Primary-Background);
}

.ac-faq {
  background-color: var(--Utility-Colors-Primary-Background);
  padding: 80px 23px 60px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;

  .ac-faq-title {
    margin: 24px;
    text-align: center;
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--Utility-Colors-Primary-Text);
  }

  .questions-container {
    border-radius: 4px;

    .filter-option {
      margin-top: 8px;
    }

    .faq {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 8px 9px;

      .option-title {
        margin: 24px 0 0;
        font-family: "Montserrat";
        color: var(--Utility-Colors-Primary-Text);
        font-weight: bold;
        font-size: 18px;
        line-height: 32px;
      }
    }

    .dropdown-divider {
      margin: 16px 0 0;
      border-bottom: 1px solid var(--Custom-Colors-Lines);
    }

    .drop-options {
      display: flex;
      padding: 24px 9px 0;
      border-bottom: 1px solid var(--Custom-Colors-Lines);

      .center-container {
        display: flex;
        justify-content: space-between;

        .options {
          .option {
            font-family: "Montserrat";
            font-weight: normal;
            font-size: 16px;
            line-height: 32px;
            color: var(--Utility-Colors-Primary-Text);
            margin-bottom: 24px;
          }
        }

        .min {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .ac-info {
    .ac-win {
      justify-content: center;

      .content {
        display: flex;
        flex-flow: row-reverse;
        align-content: flex-start;

        .order-bottom-right {
          order: 1;
        }

        h3,
        h4,
        p {
          text-align: start;
        }

        img {
          max-width: 317px;
        }

        .btn-orange {
          margin: 6% 0;
          width: 225.52px;
          height: 52px;
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .main-ac-homepage {
    .ac-find {
      height: 756px;
      padding-top: 0px;
      flex-direction: row;

      .ac-title {
        text-align: left;
        align-items: flex-start;
      }

      .ac-background {
        object-position: initial;
      }
    }
  }

  .ac-info {
    .ac-sec {
      .content {
        display: flex;
      }
    }

    .row {
      h5,
      p,
      h3,
      h4 {
        text-align: left;
      }

      .signup {
        text-align: center;
        font-size: 22px;
        line-height: 30px;
      }

      h3 {
        font-size: 42px;
        line-height: 46px;
      }

      h4 {
        font-size: 20px;
        line-height: 30px;
      }

      .btn-orange {
        margin: 27px auto;
      }
    }
  }

  .ac-faq {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;

    .ac-faq-title {
      margin-bottom: 50px;
      font-size: 42px;
      line-height: 46px;
    }

    .questions-container {
      display: flex;
      flex-flow: column;
      width: 100%;
      max-width: 1110px;

      .faq {
        align-items: center;
        height: 72px;
        width: 80%;
        margin: 0 auto;

        .option-title {
          margin: 0;
        }
      }

      .drop-options {
        display: flex;
        padding-bottom: 14px;

        .center-container {
          width: 80%;
          margin: 0 auto;

          .options {
            margin-right: 23px;

            .option {
              font-size: 16px;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .main-ac-homepage {
    .ac-find {
      max-width: 1290px;
      margin: 0 auto;
      padding: 0 20px;

      .ac-title {
        h1 {
          font-size: 50px;
          line-height: 66px;
          width: 82%;
        }

        p {
          width: 82%;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0;
        }
      }
    }
  }
}
