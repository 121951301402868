.car-insurance-modal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: scroll;
  .car-insurance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;
    padding: 12px;

    .btn-close-container {
      position: relative;
      .btn-close {
        position: absolute;
        top: 8px;
        img {
          position: absolute;
        }
      }
    }

    .car-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      h4 {
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        color: #195154;
        margin: 32px 0;
      }
      .car-data-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 12px 32px;
        
        div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          p {
            padding-right: 16px;
            font-family: "Montserrat";
            span {
              font-weight: 700;
            }
          }
        }
      }

      .help {
        margin: 16px 0 16px 6px;
        font-family: "Montserrat";
        font-size: 16px;
        line-height: 24px;
      }
    }

    .list {
      border: 1px dashed #3cb2b9;
      border-radius: 8px;
      padding: 8px;
      p {
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #195154;
        margin-top: 16px;
      }
      ul {
        li {
          padding: 12px 0;
          font-family: "Montserrat";
          font-size: 16px;
          line-height: 24px;
        }
      }
      span {
        font-weight: 700;
      }
      .own-insurance {
        p {
          padding: 12px;
          font-family: "Montserrat";
          font-size: 16px;
          line-height: 24px;
          font-style: normal;
          font-weight: 400;
          text-align: left;
        }

        .link-insurance {
          display: flex;
          justify-content: center;
          margin: 24px 0;
          svg {
            color: #3cb2b9;
          }
          button {
            border: none;
            background: inherit;
            font-family: "Montserrat";
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #3cb2b9;
          }
        }
      }
    }
  }

  .car-data-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    max-width: 800px;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;
    padding: 24px 80px;

    .btn-close-container {
      position: relative;
      .btn-close {
        position: absolute;
        right: -50px;
        top: 8px;
        img {
          position: absolute;
        }
      }
    }
    max-height: 280px;
    h4 {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: #195154;
      margin: 24px 0;
    }
    p {
      font-family: "Montserrat";
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media only screen and (min-width: 1130px) {
  .car-insurance-modal {
    padding: 30px 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    .car-insurance-container {
      .car-data-items {
        div {
          display: flex;
          justify-content: center;
        }
      }
      .list {
        margin: 12px 32px 40px 32px;
      }
      max-width: 800px;

      .help {
        margin-top: -16px !important;
        max-width: 600px;
      }
    }

    .car-data-empty {
      max-height: 220px;
    }
  }
}
