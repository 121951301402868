.a-find {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: var(--Utility-Colors-Primary-Background);

  .swiper {
    display: none;
    width: 100%;
    height: 649px;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: flex-end;
      img {
        display: block;
        width: 100%;
        height: 48%;
        object-fit: cover;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }

    .swiper-pagination {
      display: none;
    }
  }

  .carousel-vector-container {
    position: absolute;
    left: 5%;
    bottom: -40px;
    display: none;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .a-find-content {
    padding: 60px 0;
    margin: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: initial;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      gap: 64px;
      max-width: 1577px;
      margin: 0 auto;
      padding: 0;
      width: 100%;

      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 43.88px;
        color: var(--Utility-Colors-Primary-Text);
        margin-bottom: 16px;
        text-align: center;
      }

      h4 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19.5px;
        color: var(--Utility-Colors-Primary-Text);
        text-align: center;
      }

      .selectors-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 360px;
        background: var(--Brand-Colors-White-color-fix);
        border-radius: 24px;
        padding: 48px 24px 48px 24px;
        box-shadow: 0px 5px 20px 0px #00000026;
      }

      .selectors {
        .selector {
          display: flex;
          flex-direction: column;
          margin-bottom: 13px;
          .select-characteristics {
            width: 100%;
            height: 50px;
            border-radius: 40px;
            text-align: left;
            background: var(--Brand-Colors-Smoke-color-fix);

            .MuiOutlinedInput-input {
              font-family: "Montserrat";
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: 0em;
              color: var(--Brand-Colors-Black-color-fix);
            }

            svg {
              color: var(--Brand-Colors-Black-color-fix);
            }

            fieldset {
              border: none;
            }
          }

          p {
            font-family: "Montserrat";
            color: var(--Brand-Colors-Black-color-fix);
            font-weight: 500;
            font-size: 16px;
            line-height: 19.5px;
            margin-bottom: 8px;
          }

          .pink {
            color: var(--Brand-Colors-Primary-Color);
          }
        }
      }

      .btn-container {
        margin-top: 24px;
        .btn-orange {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--Brand-Colors-Primary-Color);
          box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
          border-radius: 40px;
          width: 100%;
          color: var(--Brand-Colors-White-color-fix);
          height: 47px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .error-message {
        padding: 12px 0 0 0;
        p {
          width: 100%;
          color: #d02a2a;
          text-align: "center";
          font-size: 14px;
          font-weight: normal;
          margin: 0;
        }
      }
    }
  }

  .a-find-img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 90%;
    }
  }
}

.benefits-container-anfitrion {
  padding: 60px 23px 50px;
  display: none;
  background: var(--Utility-Colors-Primary-Background);

  .title-container {
    text-align: center;
    margin-bottom: 50px;

    .title {
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: var(--Utility-Colors-Primary-Text);
    }

    p {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: var(--Utility-Colors-Secondary-Text);
      margin-bottom: 0px;
    }
  }

  .list-benefits {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    max-width: 1250px;

    .benefit {
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;
      width: 100%;
      max-width: 215px;
      margin-top: 40px;

      img {
        width: 86px;
        height: 86px;
        margin-bottom: 10px;
      }

      h3 {
        font-family: "Montserrat";
        font-weight: bold;
        color: var(--Utility-Colors-Primary-Text);
      }

      p {
        font-family: "Montserrat";
        font-weight: normal;
        color: var(--Utility-Colors-Secondary-Text);
      }

      h3,
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.steps-container {
  position: relative;
  .un-steps {
    .information-container {
      .top-container {
        order: 1;
        margin-top: 32px;
      }
    }
  }

  .sprinkles-vector-container {
    position: absolute;
    right: 10%;
    top: 120px;
    display: none;
    z-index: 1;

    img {
      width: 100%;
    }
  }
}

.a-info {
  position: relative;
  .row {
    margin: 0;
    width: 100%;
    display: flex;

    .order-bottom-right {
      order: unset;
    }

    .order-top-right {
      order: 1;
    }

    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 51.2px;
      color: var(--Utility-Colors-Primary-Text);
      text-align: center;
    }

    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: var(--Utility-Colors-Primary-Text);
      text-align: left;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--Utility-Colors-Primary-Text);
    }

    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--Utility-Colors-Secondary-Text);
      margin: 0 0 10px;
    }

    .a-img-container {
      float: left;
      height: 24px;
      margin: 0 0 10px;
    }
  }

  .a-sec {
    padding: 10% 10%;
    background: var(--Utility-Colors-Primary-Background);

    .content {
      gap: 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1577px;

      .content-text {
        max-width: 429px;
        padding: 0;
        text-align: justify;
        order: 2;

        p {
          text-align: left;
        }

        .card {
          background: transparent;
          border: 0px;
          max-width: 370px;

          .card-body {
            display: flex;
            flex-wrap: wrap;
            background-color: transparent;
            padding: 0;

            .card-title-text {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: var(--Utility-Colors-Primary-Text);
              margin: 10px 10px 10px 0;
              display: none;
            }

            .list-right,
            .list-left {
              margin-bottom: 2px;
              padding-left: 0px;
              margin-top: 32px;

              li {
                display: flex;
                gap: 20px;
                list-style: none;
                font-family: "Montserrat";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--Utility-Colors-Primary-Text);
                margin-bottom: 15px;
              }
            }

            a {
              text-decoration: underline;
              color: var(--Brand-Colors-Primary-Color);
              margin: 10px 10px 10px 0;
            }
          }
        }
      }

      .content-img {
        order: 1;
      }

      .btn-orange {
        display: flex;
        width: 100%;
        height: 52px;
        align-items: center;
        justify-content: center;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: 0;
        color: var(--Brand-Colors-White-color-fix);
        font-family: "Montserrat";
        width: 312px;
        order: 3;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .a-win {
    padding: 64px 20px;
    display: flex;
    justify-content: center;
    background: var(--Utility-Colors-Primary-Background);

    .content {
      display: flex;
      flex-direction: column;
      max-width: 500px;

      h3,
      p {
        text-align: center;
      }

      .win-container {
        display: flex;
        justify-content: center;
      }

      img {
        max-width: 574px;
      }

      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: 50px auto;
        color: var(--Brand-Colors-White-color-fix);
        width: 100%;
        height: 52px;
        font-family: "Montserrat";
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }

      .text-title {
        display: flex;
        justify-content: center;
      }

      .order-top-right {
        order: 0;
      }

      .pink {
        color: var(--Brand-Colors-Primary-Color);
      }
    }
  }

  .speed-up-earnings-vector-container {
    position: absolute;
    left: 5%;
    bottom: -40px;
    display: none;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .testimony-one-vector-container {
    position: absolute;
    left: 5%;
    top: 180px;
    display: none;
    z-index: 1;
    width: 62px;
    height: 53px;

    img {
      width: 100%;
    }
  }

  .testimony-three-vector-container {
    position: absolute;
    right: 5%;
    bottom: 50%;
    display: none;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .testimony-one-vector-container-mobile {
    position: absolute;
    right: 20%;
    top: 20px;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .testimony-three-vector-container-mobile {
    position: absolute;
    left: 10%;
    bottom: 15%;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .community-vector-container {
    position: absolute;
    right: 10%;
    top: 120px;
    display: none;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .un-lote {
    padding: 60px 0 70px;
    background: var(--Utility-Colors-Primary-Background);

    .content {
      display: flex;
      flex-direction: column;
      max-width: 1577px;
      margin: 0 auto;

      h3 {
        font-size: 24px;
        line-height: 29.26px;
      }

      h4 {
        font-size: 18px;
        line-height: 21.94px;
        text-align: center;
      }

      .container-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .text-container {
        justify-content: center;
        display: flex;
        flex-flow: column;
      }

      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: 8% auto;
        color: var(--Brand-Colors-White-color-fix);
        width: 248px;
        height: 52px;
        font-family: "Montserrat";
        font-weight: 700;
      }

      .img-container {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        img {
          max-width: 476px;
        }
      }
    }
  }

  .arrow-vector-container {
    position: absolute;
    left: 50%;
    top: 76px;
    display: none;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .last {
    padding: 60px 0;
    background: var(--Utility-Colors-Secondary-Background);

    .content {
      h3 {
        font-size: 24px;
        line-height: 29.26px;
      }

      h4 {
        text-align: center;
      }
    }

    .btn-orange {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      margin: 8% auto;
      color: var(--Brand-Colors-White-color-fix);
      width: 248px;
      height: 52px;
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.a-steps {
  padding: 10%;

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }

  h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: var(--Utility-Colors-Primary-Text);
  }

  .row {
    margin: 0 auto;
    width: 100%;
    max-width: 1120px;

    img {
      width: 64px;
    }

    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      width: 248px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.awards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;

  .awards-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    margin-bottom: 60px;

    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
    }

    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #3cb2b9;
    }

    .awards-info {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #333333;
      max-width: 450px;
    }
  }

  .awards-pedestal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    .medal-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 40px;
      width: 283px;

      img {
        width: 116.52px;
        height: 164.51px;
        margin-bottom: 28px;
      }

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .a-info {
    .order-bottom-right {
      order: 1;
    }

    .a-sec {
      padding: 50px 23px;
      margin: 0px;
      display: flex;
      justify-content: center;

      .content {
        align-items: center;

        .content-text {
          order: 1;
        }

        .content-img {
          order: 2;
        }

        img {
          max-width: 538px;
        }

        h3,
        h4 {
          text-align: left;
        }

        .btn-orange {
          order: inherit;
        }
      }
    }

    .a-win {
      padding: 82px 20px 82px;

      .content {
        display: flex;
        flex-flow: row-reverse;
        align-content: flex-start;
        max-width: 1272px;

        .win-container {
          align-items: flex-end;
        }

        h3,
        h4,
        p {
          text-align: left;
        }

        p {
          max-width: 450px;
        }

        h3 {
          white-space: nowrap;
          margin-bottom: 16px;
        }

        h4 {
          font-size: 36px;
          line-height: 42px;
          margin-bottom: 24px;
        }

        .btn-orange {
          display: none;
        }

        .order-top-right {
          order: 1;
        }

        .text-title {
          margin-bottom: 47px;
        }

        .container-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    .un-lote {
      .content {
        display: flex;
        flex-flow: row-reverse;
        align-content: flex-start;

        h3,
        h4,
        p,
        h5 {
          text-align: start;
        }

        h3 {
          font-size: 42px;
          line-height: 51.2px;
        }

        h4 {
          font-size: 20px;
          line-height: 24.38px;
        }

        .btn-orange {
          margin: 6% 0;
          width: 248px;
          height: 52.839px;
        }
      }
    }

    .testimony-one-vector-container-mobile,
    .testimony-three-vector-container-mobile {
      display: none;
    }
  }

  .benefits-container-anfitrion {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 800px) {
  .a-find {
    .swiper {
      margin-top: 0;
      max-height: 800px;
      .swiper-slide {
        max-height: 800px;
        align-items: center;

        img {
          height: 100%;
        }
      }
    }

    .a-container-title {
      width: 77vh;
      height: 100%;
      right: 0;
      max-width: 750px;

      .a-orange-shadow {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0px;

        .a-title {
          padding: 0 40px 0 0;
          align-items: flex-end;
          justify-content: center;
          top: 0;
          right: 0;
          width: 96%;
          height: 100%;
          background-color: transparent;
          box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.15);
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 0px;

          .btn-container {
            .btn-orange {
              margin: 58px 0 0;
            }
          }

          h1 {
            font-size: 36px;
            line-height: 44px;
            width: 82%;
            margin-bottom: 19px;
            text-align: right;
          }

          p {
            width: 82%;
            font-size: 20px;
            text-align: center;
            line-height: 24px;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  .a-info {
    .a-sec {
      .content {
        display: flex;
        width: 81%;
        justify-content: space-around;

        .content-text {
          .card {
            width: 476px;

            .card-title-text {
              display: initial;
            }

            .list-left {
              margin-top: 0;
            }
          }

          .btn-orange {
            margin: 60px 0;
            width: 248px;
          }

          h4 {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }

    .row {
      h5,
      p,
      h3,
      h4 {
        text-align: left;
      }

      h3 {
        font-size: 42px;
        line-height: 46px;
      }
    }

    .last {
      padding: 80px 0;

      .content {
        h3 {
          font-size: 42px;
          line-height: 51.2px;
        }

        h4 {
          font-size: 20px;
          line-height: 24.38px;
          font-weight: 600;
        }
      }

      .btn-orange {
        margin: 27px auto;
      }

      .join {
        br {
          display: none;
        }
      }

      .ready,
      .join {
        text-align: center;
      }
    }
  }

  .a-steps {
    h3 {
      font-size: 42px;
      line-height: 46px;
    }

    h4 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 65px;
    }
  }

  .awards {
    padding: 93px 0 125px;
    .awards-main {
      h3,
      h4 {
        font-size: 42px;
        line-height: 46px;
      }

      .awards-info {
        font-weight: 600;
        font-size: 20px;
        max-width: 602px;
        line-height: 30px;
      }
    }

    .awards-pedestal {
      #gold {
        order: 3;
      }

      #first_silver {
        order: 4;
      }

      #second_silver {
        order: 2;
      }

      #first_bronze {
        order: 1;
      }

      #second_bronze {
        order: 5;
      }
    }
  }

  .steps-container {
    .un-steps {
      .information-container {
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        gap: 102px;

        .top-container {
          order: 2;
          margin-top: 0;
        }

        .bottom-container {
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 820px) {
  .a-find {
    #a_background {
      display: none;
    }

    #a_background_des {
      display: block;
    }
  }

  .benefits-container-anfitrion {
    display: flex;
    padding: 63px 23px 50px;
    flex-direction: column;
    align-items: center;

    .title-container {
      text-align: center;

      .title {
        font-size: 50px;
        line-height: 60.95px;
      }

      p {
        font-size: 20px;
        line-height: 24.38px;
        margin: 0 auto;
        color: var(--Utility-Colors-Primary-Text);
      }
    }

    .list-benefits {
      justify-content: space-between;

      .benefit {
        align-items: center;
        text-align: center;
        margin-top: 30px;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .a-find {
    flex-direction: row;
    .swiper {
      display: flex;
    }

    .a-container-title {
      width: 70%;

      .a-orange-shadow {
        .a-title {
          align-items: center;
          padding: 0 0 0 136px;

          h1 {
            text-align: center;
          }
        }
      }
    }

    .a-find-content {
      height: 600px;
      position: absolute;
      top: 0;
      z-index: 1;

      .content {
        flex-direction: row;

        h3 {
          font-weight: 700;
          font-size: 50px;
          line-height: 60.95px;
          color: var(--Brand-Colors-White-color-fix);
          margin-bottom: 24px;
          text-align: left;
        }

        h4 {
          font-weight: 700;
          font-size: 24px;
          line-height: 29.26px;
          color: var(--Brand-Colors-White-color-fix);
          width: 375px;
          text-align: left;
        }
      }
    }

    .carousel-vector-container {
      display: initial;
    }

    .a-find-img-container {
      display: none;
    }

    .speed-up-earnings-vector-container {
      display: initial;
    }

    .sprinkles-vector-container {
      display: initial;
    }

    .community-vector-container {
      display: initial;
    }

    .arrow-vector-container {
      display: initial;
    }
  }
}

@media only screen and (min-width: 1166px) {
  .a-find {
    .testimony-one-vector-container {
      display: initial;
    }

    .testimony-three-vector-container {
      display: initial;
    }
  }
}
