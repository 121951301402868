.nav-container {
  height: 80px;
  background: var(--Utility-Colors-Primary-Background);
  .navbar {
    margin-bottom: 0;
    box-shadow: 0px 8px 25px rgb(0 0 0 / 10%);
    height: 100%;
    padding: 0;

    .container-fluid {
      margin: 0 5%;

      .logo-center {
        order: 3;
      }

      .logo-container {
        display: none;
      }

      .logo-desktop {
        order: 3;
        display: none;
      }

      .logo_orange {
        display: none;
      }

      #main_logo {
        display: none;
        max-width: 160px;
      }

      #min_logo {
        display: block;
        max-width: 48px;
      }

      .btn-create-account {
        order: 2;
        width: 205px;
        height: 38px;
        border-radius: 60px;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        background-image: linear-gradient(
          to right,
          rgb(255, 127, 200),
          rgba(250, 189, 200, 1)
        );
        color: var(--Brand-Colors-White-color-fix);
        display: flex;
        border: none;
        justify-content: center;
        align-items: center;
      }

      .btn-upload-car {
        order: 2;
        width: 205px;
        height: 35px;
        border-radius: 20px;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 16px;
        padding: 9px 32px !important;
        line-height: 16px;
        display: flex;
        border: none;
        justify-content: center;
        align-items: center;
      }

      .navbar-unauthenticated {
        display: none;
      }

      .back {
        opacity: 0.5;
        z-index: 4;
        top: 80px;
      }

      .nav-collapse {
        overflow: scroll;
        z-index: 5;
        background-color: var( --Utility-Colors-Primary-Background);
        position: fixed;
        top: 0;
        left: -375px;
        height: 100%;
        margin-top: 80px;
        width: 375px;
        transition: left 0.5s;
        padding: 18px 24px 70px;

        hr {
          margin: 20px 0;
          background: var(--Utility-Colors-Secondary-Text);
        }

        .collapse-icons {
          margin: 0 10px 0 0;
        }

        .nav-item {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 16px;
          font-style: normal;
          line-height: 19.5px;
          color: var(--Utility-Colors-Primary-Text);
          text-decoration: none;
          width: 100%;
          display: flex;
        }

        .item.logout {
          color: #eb5757;
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }

        .item {
          color: var(--Utility-Colors-Primary-Text);
          display: flex;
          font-family: "Montserrat";
          font-weight: 400;
          margin: 20px 0;
          text-decoration: none;
          border: 0;
          background-color: transparent;
          padding: 0;
        }

        .item-sesion {
          margin-bottom: 7px;
        }

        .social {
          background: linear-gradient(
            227.91deg,
            #ffab03 29.49%,
            var(--Brand-Colors-Primary-Color) 73.49%
          );
          width: 40px;
          height: 40px;
        }

        .dropdown-item:hover,
        .dropdown-item:active {
          background-color: transparent;
          color: var(--Utility-Colors-Primary-Text);
        }
        .user-type {
          img {
            margin-left: 5px;
          }
        }
      }

      .menu-button {
        order: 1;
        width: 32px;
        height: 32px;
      }
      .navbar-toggler:focus {
        box-shadow: none;
      }

      .host-label {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0;
        color: var(--Utility-Colors-Secondary-Text);
      }
    }

    .container-fluid::before,
    .container-fluid::after {
      content: none;
    }

    .log-collapse {
      overflow: scroll;
      z-index: 5;
      background-color: var(--Utility-Colors-Primary-Background);
      position: fixed;
      top: 0;
      left: -375px;
      height: 100%;
      margin-top: 80px;
      width: 375px;
      transition: left 0.5s;
      padding: 20px 34px 100px;

      .arrow-btn {
        border-radius: 15px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        border: 0;
        justify-content: center;
        width: 80px;

        p {
          margin-bottom: 0;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          color: var(--Utility-Colors-Secondary-Text);
        }
      }
    }

    .login-content {
      h3 {
        text-align: left;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24.38px;
        margin: 60px 0 40px;
        color: var(--Utility-Colors-Primary-Text);
      }

      .other-signin {
        hr {
          background: var(--Utility-Colors-Secondary-Background);
          opacity: 0.45;
          width: 40%;
        }

        .or {
          margin: 30px auto;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: var(--Utility-Colors-Secondary-Text);
        }

        .btn-social {
          margin: auto;
          width: 312px;
          height: 38px;
          padding-left: 54px;
          font-family: "Montserrat";
          font-weight: 600;
          color: var(--Utility-Colors-Primary-Text);
          font-size: 14px;
          border: 1px solid var(--Utility-Colors-Secondary-Background);
          background: var(--Utility-Colors-Secondary-Background);
          box-sizing: border-box;
          border-radius: 40px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;

          img {
            margin-right: 20px;
          }
        }
      }

      .form {
        display: flex;
        align-items: flex-start;
        flex-flow: column;

        p {
          margin: 0 0 16px 0;
          padding-left: 0;
          font-size: 16px;
          color: var(--Utility-Colors-Secondary-Text);
        }

        a {
          font-size: 14px;
          color: var(--Brand-Colors-Secondary-Color);
        }

        a,
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
        }
      }

      .dropdown-item {
        background-color: transparent;
      }

      .log-input {
        margin: 0;
        border: 1px solid var(--Utility-Colors-Secondary-Background);
        height: 38px;
        padding: 10px 10px 10px 16px;
        background: var(--Utility-Colors-Secondary-Background);
        color: var(--Utility-Colors-Primary-Text);
      }

      .pss-input {
        width: 294px;
        border-radius: 40px 0px 0px 40px;
        border-right: 0px;
      }

      .email-input {
        width: 312px;
        border-radius: 40px;
      }

      .btn-orange,
      .btn-create,
      .btn-pink {
        border-radius: 40px;
      }

      .input-group-addon {
        border: 1px solid var(--Utility-Colors-Secondary-Background);
        background: var(--Utility-Colors-Secondary-Background);
        border-radius: 0px 40px 40px 0px;
        border-left: 0px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15%;
        position: relative;
        right: 1px;
      }

      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
      }

      .btn-pink {
        background-image: linear-gradient(
          to right,
          rgb(255, 127, 200),
          rgba(250, 189, 200, 1)
        );
      }

      .btn-orange,
      .btn-create,
      .btn-pink {
        margin: auto;
        height: 40px;
        width: 312px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        color: var(--Brand-Colors-White-color-fix);
        font-size: 16px;
        justify-content: center;
        display: flex;
        align-items: center;
      }

      .btn-create {
        background: #3cb2b9;
      }

      .forgot {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        text-decoration-line: underline;
        display: flex;
        justify-content: flex-end;
        color: var(--Brand-Colors-Secondary-Color);
        margin: 4px 0 24px;
        padding-left: 0;
        text-underline-offset: 3px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .a-navbar {
    background: var(--Utility-Colors-Primary-Background);
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .a-navbar-orange {
    background: var(--Brand-Colors-Primary-Color);
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
    position: relative;

    .nav-item {
      color: var(--Utility-Colors-Primary-Text);
    }
  }

  .transparent-navbar {
    background-color: transparent;
    position: absolute;
    z-index: 2;
    width: 100%;
    box-shadow: 0px 0px 0px !important;

    .nav-item {
      color: #195154 !important;
    }
  }

  .h-navbar {
    background-color: transparent;
    position: absolute;
    z-index: 2;
    width: 100%;
  }

  .nav-container {
    .navbar {
      .container-fluid {
        margin: 0 auto;
        max-width: 994px;

        .logo-container {
          display: flex;
          align-items: center;
        }

        .logo-desktop {
          order: 1;
          display: block;
        }

        .btn-upload-car {
          order: initial;
        }

        .logo-center {
          margin: 0;
          order: 1;
        }

        #main_logo {
          display: block;
        }

        #min_logo {
          display: none;
        }

        .logo_orange {
          display: block;
          max-width: 160px;
        }

        .logo {
          display: none;
        }

        .host-label {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          margin: 8px 0 0 13px;
          color: var(--Utility-Colors-Secondary-Text);
          gap: 4px;
          display: flex;
          align-items: center;
        }

        .host-status-icon {
          width: 10px;
          height: 10px;
        }

        .anfitrion {
          .nav-item {
            color: #195154;
          }
        }

        .btn-create-account {
          order: 3;
        }

        .navbar-unauthenticated {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 63%;
          order: 2;

          .ac {
            margin-left: 314px;
            top: 30px;
            box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
            transform: translateX(-140%);
          }

          #user-menu {
            left: 7vh;
          }

          .links {
            color: var(--Utility-Colors-Primary-Text);
            padding: 0;
            margin-right: 20px;
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 16px;
            white-space: nowrap;
            cursor: pointer;
          }

          .profile-image {
            width: 24px;
            height: 24px;
            border-radius: 40px;
            object-fit: cover;
          }

          .menu-log {
            padding-top: 17px;
          }

          .dropdown-menu-right {
            left: -240%;
            top: 30px;
            transform: translateX(-50%);
            width: 355px;
            padding: 0 22px 26px;

            .triangle-menu-right {
              top: -9px;
              margin: 0 auto;
              position: absolute;
              left: initial;
              right: 28px;
              filter: var(--Custom-Colors-Svg-Invert);
            }

            .session-text {
              padding-left: 24px;
              margin-bottom: 2px;
            }
            .dropdown-item {
              padding-left: 16px;
            }

            .logout-driver {
              display: flex;
              margin: 0 auto;
              width: 275px;
              height: 38px;
              padding: 7px 32px;
              justify-content: center;
              align-items: center;
              border-radius: 40px;
              border: none;
              background: var(--Utility-Colors-Primary-Text);
              color: var(--Utility-Colors-Primary-Background);
              font-size: 14px;
              font-weight: 700;
              font-family: "Montserrat";
            }
          }

          #navbar-unauthenticated {
            font-weight: 700;
          }

          .dropdown-menu {
            margin-top: 15px;
            border-radius: 10px;
            background: var(--Utility-Colors-Secondary-Background);
            border: none;
            padding: 24px;

            .other-signin {
              hr {
                background: var(--Utility-Colors-Secondary-Background);
                width: 40%;
              }

              .or {
                margin: 30px auto;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: var(--Utility-Colors-Secondary-Text);
              }

              .btn-social {
                margin: auto;
                width: 312px;
                height: 38px;
                padding-left: 54px;
                font-family: "Montserrat";
                font-weight: 600;
                color: var(--Utility-Colors-Primary-Text);
                font-size: 14px;
                border: 1px solid var(--Utility-Colors-Primary-Background);
                background: var(--Utility-Colors-Primary-Background);
                box-sizing: border-box;
                border-radius: 40px;
                margin-bottom: 8px;
                display: flex;
                align-items: center;

                img {
                  margin-right: 20px;
                }
              }
            }

            .triangle-menu {
              top: -9px;
              margin: 0 auto;
              position: absolute;
              left: 0;
              right: 0;
              filter: var(--Custom-Colors-Svg-Invert);
            }

            .form {
              display: flex;
              align-items: center;
              flex-flow: column;

              p {
                margin-bottom: 0px;
                color: var(--Utility-Colors-Primary-Text);
                padding: 0;
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 16px;
              }
            }

            .dropdown-divider {
              width: 90%;
              margin: 5px auto;
              background: var(--Utility-Colors-Primary-Text);
            }

            .dropdown-item {
              background-color: transparent;
              color: var(--Utility-Colors-Primary-Text);
            }

            .log-input {
              border: 1px solid var(--Utility-Colors-Secondary-Background);
              margin: 0 0 24px;
              padding-left: 9px;
              background: var(--Utility-Colors-Primary-Background);
              color: var(--Utility-Colors-Primary-Text);
            }

            .pss-input {
              width: 292px;
              height: 38px;
              border-radius: 40px 0px 0px 40px;
              border-right: 0px;
              font-size: 14px;
              padding-left: 16px;
              margin: 0 0 4px;
            }

            .email-input {
              width: 318px;
              height: 38px;
              border-radius: 40px;
              font-size: 14px;
              padding-left: 16px;
            }

            .input-group-addon {
              border: 1px solid var(--Utility-Colors-Primary-Background);
              background: var(--Utility-Colors-Primary-Background);
              border-radius: 0px 40px 40px 0px;
              border-left: 0px;
              height: 36px;
              margin-top: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 13%;
              position: relative;
              right: 1px;

              img {
                margin: 6px;
              }
            }

            .btn-orange,
            .btn-pink {
              border-radius: 40px;
              width: 160px;
              color: var(--Brand-Colors-White-color-fix);
              font-size: 14px;
              line-height: 19px;
              margin: 0 auto 16px;
              width: 100%;
              height: 40px;
            }

            .btn-pink {
              background-image: linear-gradient(
                to right,
                rgb(255, 127, 200),
                rgba(250, 189, 200, 1)
              );
            }

            .btn-orange {
              background: var(--Brand-Colors-Primary-Color);
            }

            .btn-orange,
            .create,
            .btn-pink {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: bold;
            }

            .create {
              font-size: 16px;
              text-align: center;
              color: #195154;
            }

            .forgot-container {
              width: 100%;
            }

            .forgot {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              display: flex;
              justify-content: flex-start;
              text-decoration-line: underline;
              color: var(--Brand-Colors-Secondary-Color);
              margin: 0 auto 16px;
              justify-content: flex-end;
            }
          }

          .earn-money {
            .dropdown-menu {
              width: 225px;
              padding: 10px;
            }
            .dropdown-divider {
              width: 100%;
            }
          }

          .login {
            .dropdown-toggle::after {
              display: none;
            }
            .dropdown-menu {
              margin-left: -132px;
            }
          }

          .log {
            width: 438px;
            height: 262px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1250px) {
  .nav-container {
    .navbar {
      .container-fluid {
        .navbar-unauthenticated {
          #user-menu {
            left: 6vh;
          }
        }
      }
    }
  }
}
