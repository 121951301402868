input,
button {
  outline: none !important;
}
.footer-container {
  padding: 35px 16px;
  background: var(--Utility-Colors-Secondary-Background);
  .container {
    max-width: 1338px;
    .row {
      .social-language-list {
        .social,
        .language {
          margin-bottom: 8px;
          .subtitle {
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: var(--Utility-Colors-Primary-Text);
            margin-bottom: 0;
          }

          .dropdown-language {
            .MuiSelect-select {
              display: flex;
              align-items: center;
              padding: 10px 29px 10px 0;
            }

            .MuiOutlinedInput-notchedOutline {
              border: 0;
            }
          }
        }
        .theme {
          margin-bottom: 8px;
          .subtitle {
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: var(--Utility-Colors-Primary-Text);
            margin-bottom: 0;
          }

          .dropdown-theme {
            .MuiSelect-select {
              display: flex;
              align-items: center;
              padding: 10px 29px 10px 0;
            }

            .MuiOutlinedInput-notchedOutline {
              border: 0;
            }
          }
        }

        .social {
          margin-top: 32px;
          margin-right: 16px;
          margin-left: 16px;

          .d-flex {
            gap: 10px;

            .social-item {
              border-radius: 6px;
              width: 32px;
              height: 32px;
              padding: 4px;
              background: var(--Utility-Colors-Primary-Background);
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .language {
          background: var(--Utility-Colors-Primary-Background);
          border-radius: 6px;
          padding: 6px 12px;
          width: 180px;

          .MuiInputBase-root {
            width: 170px;

            .MuiBox-root {
              font-family: Montserrat;
              font-size: 16px;
              color: var(--Utility-Colors-Primary-Text);
            }
          }
        }
        .theme {
          background: var(--Utility-Colors-Primary-Background);
          border-radius: 6px;
          padding: 6px 12px;
          width: 180px;

          .MuiInputBase-root {
            width: 170px;

            .MuiBox-root {
              font-family: Montserrat;
              font-size: 16px;
              color: var(--Utility-Colors-Primary-Text);
            }
          }
        }
      }

      .footer-titles {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: var(--Utility-Colors-Primary-Text);
      }

      button {
        background-color: transparent;
      }

      .list-inline {
        display: flex;
        flex-flow: column;
        margin-bottom: 0;
        .list-inline-item {
          margin-bottom: 24px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: var(--Utility-Colors-Primary-Text);
          word-break: break-word;
          white-space: break-spaces;

          a {
            display: flex;
            text-decoration: none;
            color: var(--Utility-Colors-Primary-Text);
          }
          img {
            margin-right: 6px;
          }
        }
      }

      .list-unstyled {
        li {
          margin-bottom: 24px;
        }

        a {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: var(--Utility-Colors-Primary-Text);
          text-decoration: none;
        }
      }
    }
  }
}

.flag-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
